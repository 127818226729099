import React from 'react';
import {
  DidomiButton,
  DidomiIllustration,
  DidomiModal,
  DidomiModalHeader,
  DidomiModalActions,
  DidomiButtonGroup,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { TRACKING_SELECTORS, USER_ID_TYPE_TO_TEXT } from '@constants';
import { ConsentProofReport } from '@types';

type ConfirmStopReportModalProps = {
  isOpen: boolean;
  report: ConsentProofReport;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ConfirmStopReportModal = ({
  isOpen,
  report,
  onCancel,
  onConfirm,
}: ConfirmStopReportModalProps) => {
  return (
    <DidomiModal isOpen={isOpen} permanent variant="slim">
      <DidomiIllustration className={tx('mb-m')} name="stop-report" />
      <DidomiModalHeader
        modalSubTitle="This generating report will be stopped and deleted:"
        modalTitle={`${USER_ID_TYPE_TO_TEXT[report?.search_parameters.user_id_type]}: ${
          report?.search_parameters.user_id
        }`}
      />

      <DidomiModalActions>
        <DidomiButtonGroup>
          <DidomiButton variant="secondary" onClick={onCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton
            data-cy="confirm-btn"
            data-tracking={TRACKING_SELECTORS.proofs.confirmStopReportBtn}
            onClick={onConfirm}
          >
            Stop and Delete it
          </DidomiButton>
        </DidomiButtonGroup>
      </DidomiModalActions>
    </DidomiModal>
  );
};
