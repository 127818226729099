import React, { useCallback, useMemo, useState } from 'react';
import { filterSortPaginate, getCounterText } from '@didomi/helpers';
import { useTableState } from '@didomi/helpers-react';
import { DidomiBottomBar, DidomiEmptyState, DidomiPaginator } from '@didomi/ui-atoms-react';
import { DeploymentsFilters, Loader, NoticeDeploymentsTable, ResultsText } from '@components';
import { TRACKING_SELECTORS } from '@constants';
import { useNoticeDeploymentsApi } from '@hooks';
import { ConsentNoticePreviewModal, NoticeDeploymentNotesModal } from '@modals';
import { NoticeDeploymentsFilters } from '@types';
import { matchNoticeDeploymentsFilters } from '@utils';
import { DIALOG_CLOSED_STATE, DialogState, INITIAL_TABLE_STATE } from './NoticeVersionsHelpers';

export const NoticeVersions = () => {
  const [dialogState, setDialogState] = useState<DialogState>(DIALOG_CLOSED_STATE);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { extendedNoticeDeployments, downloadDeploymentJson, downloadDeploymentsCsv, isLoading } =
    useNoticeDeploymentsApi();

  const tableState = useTableState(INITIAL_TABLE_STATE);
  const filterDeployments = useCallback(
    matchNoticeDeploymentsFilters(tableState.filters as unknown as NoticeDeploymentsFilters),
    [tableState.filters],
  );

  const { data: noticeDeploymentsToDisplay, total: paginationTotal } = useMemo(
    () =>
      filterSortPaginate(extendedNoticeDeployments, {
        search: tableState.search,
        sortConfig: tableState.sortConfig,
        limit: tableState.limit,
        page: tableState.page,
        searchFields: [],
        filters: [filterDeployments],
      }),
    [tableState, extendedNoticeDeployments, filterDeployments],
  );

  const isEmpty = noticeDeploymentsToDisplay?.length === 0;
  const isBottomBarOpened = selectedIds.length > 0;

  return (
    <section className="w-full">
      <DeploymentsFilters
        className="mb-s block"
        deployments={extendedNoticeDeployments}
        onFiltersChange={tableState.changeFilters}
      />

      {!isEmpty && (
        <>
          <ResultsText count={paginationTotal} />

          <NoticeDeploymentsTable
            className="mb-xs"
            noticeDeployments={noticeDeploymentsToDisplay}
            sortConfig={tableState.sortConfig}
            selectedIds={selectedIds}
            onSortChange={tableState.changeSorting}
            onDownloadJsonClicked={downloadDeploymentJson}
            onViewNotesClicked={deployment =>
              setDialogState({ type: 'notes', context: deployment })
            }
            onSelectionChange={ids => setSelectedIds(ids)}
            onPreviewClicked={deployment =>
              setDialogState({ type: 'preview', context: deployment })
            }
          />

          <div className="flex justify-end">
            <DidomiPaginator
              data-testid="versions-paginator"
              className="self-end"
              page={tableState.page}
              itemCount={paginationTotal}
              size={tableState.limit as any}
              onPageSizeChange={tableState.changeLimit}
              onPageChange={tableState.changePage}
            />
          </div>
        </>
      )}

      <DidomiBottomBar
        class="-mx-l !fixed w-full"
        buttonText="Extract .CSV"
        icon="check"
        isOpen={isBottomBarOpened}
        text={isBottomBarOpened ? `${getCounterText(selectedIds.length, 'file')} selected` : null}
        data-tracking={TRACKING_SELECTORS.versions.extractCsv}
        onApply={() => downloadDeploymentsCsv(selectedIds, { onSuccess: () => setSelectedIds([]) })}
        onClose={() => setSelectedIds([])}
      />

      {/* Empty State */}
      {isEmpty && !isLoading && (
        <DidomiEmptyState illustration-name="traces-no-result-found">
          <div slot="title">It&apos;s empty here!</div>
          No results
        </DidomiEmptyState>
      )}
      {/* Loading State */}
      {isLoading && <Loader>Loading notices</Loader>}

      {/* Modals */}
      <NoticeDeploymentNotesModal
        isOpen={dialogState.type === 'notes'}
        deployment={dialogState.context}
        onClose={() => setDialogState(DIALOG_CLOSED_STATE)}
      />
      <ConsentNoticePreviewModal
        isOpen={dialogState.type === 'preview'}
        noticeDeployment={dialogState.context}
        onClose={() => setDialogState(DIALOG_CLOSED_STATE)}
      />
    </section>
  );
};
