/**
 * DOM selectors to track for analytics
 */

export const TRACKING_SELECTORS = {
  common: {
    helpCenterLink: 'versions-proofs-help-center-link',
  },
  versions: {
    downloadButton: 'notice-version-download-button',
    previewButton: 'notice-version-view-button',
    openNotesButton: 'notice-version-open-notes-button',
    filter: 'notice-version-filter',
    extractCsv: 'notice-version-extract-csv',
  },
  proofs: {
    generateReportBtn: 'consent-proofs-generate-report-button',
    stopReportBtn: 'consent-proofs-stop-report-button',
    confirmStopReportBtn: 'consent-proofs-confirm-stop-report-button',
    extractCsvBtn: 'consent-proofs-extract-csv-button',
  },
};
