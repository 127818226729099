import React from 'react';
import { SortConfig } from '@didomi/helpers';
import {
  DidomiTable,
  DidomiTableHeading,
  DidomiTableHeaderRow,
  DidomiTableTh,
  DidomiTableBody,
  DidomiTableRow,
  DidomiTableTd,
  DidomiListShortener,
  DidomiTooltip,
} from '@didomi/ui-atoms-react';
import { ExtendedNoticeDeployment } from '@types';
import { DateTableCell } from '../DateTableCell/DateTableCell';
import { DeploymentTableActions } from '../DeploymentTableActions/DeploymentTableActions';
import { PlatformTableCell } from '../PlatformTableCell/PlatformTableCell';

interface NoticeDeploymentsTableProps {
  className?: string;
  noticeDeployments: ExtendedNoticeDeployment[];
  sortConfig: SortConfig;
  selectedIds: string[];
  onSortChange: (sortConfig: CustomEvent<{ sortId: string; direction: 'asc' | 'desc' }>) => void;
  onDownloadJsonClicked: (deploymentId: string) => void;
  onViewNotesClicked?: (deployment: ExtendedNoticeDeployment) => void;
  onSelectionChange: (selectedIds: string[]) => void;
  onPreviewClicked: (deployment: ExtendedNoticeDeployment) => void;
}

export const NoticeDeploymentsTable = ({
  className,
  noticeDeployments,
  sortConfig,
  selectedIds,
  onSortChange,
  onDownloadJsonClicked,
  onViewNotesClicked,
  onSelectionChange,
  onPreviewClicked,
}: NoticeDeploymentsTableProps) => (
  <DidomiTable
    className={className}
    sortBy={sortConfig.field}
    sortDirection={sortConfig.dir}
    sortable
    selectable
    selectedItems={selectedIds}
    selectOneTooltip="Select for extraction"
    onSortChange={onSortChange}
    onToggleAllRowsSelectedChange={e => onSelectionChange(e.detail.newSelectedItems)}
    onRowSelectionChange={e => onSelectionChange(e.detail.newSelectedItems)}
  >
    <DidomiTableHeading>
      <DidomiTableHeaderRow>
        <DidomiTableTh data-cy="notice-th" sortId="notice_name">
          NOTICE
        </DidomiTableTh>
        <DidomiTableTh sortId="notice_deployment.id">VERSION ID</DidomiTableTh>
        <DidomiTableTh sortId="platform">TYPE</DidomiTableTh>
        <DidomiTableTh data-cy="date-th" sortId="deployed_at">
          PUBLISH DATE
        </DidomiTableTh>
        <DidomiTableTh>TARGET</DidomiTableTh>
        <DidomiTableTh sortId="deployed_by_name">USER</DidomiTableTh>

        {/* Actions Column */}
        {<DidomiTableTh></DidomiTableTh>}
      </DidomiTableHeaderRow>
    </DidomiTableHeading>

    <DidomiTableBody>
      {/** There is a bug in the ui-library that breaks re-rendering the rows dynamically - wrapping the content in a div fixes the issue */}
      <div>
        {noticeDeployments.map(deployment => (
          <DidomiTableRow key={deployment.id} selectionValue={deployment.id}>
            <DidomiTableTd data-cy="notice-td">
              <DidomiTooltip className="w-full" content={deployment.notice_name} onlyIfTruncated>
                <div className="leading-4 truncate">{deployment.notice_name}</div>
              </DidomiTooltip>
              <div className="text-neutral-gray-6 text-row-secondary leading-4">
                {deployment.notice_id}
              </div>
            </DidomiTableTd>
            <DidomiTableTd>{deployment.notice_deployment.id}</DidomiTableTd>
            <DidomiTableTd>
              <PlatformTableCell platform={deployment.platform} />
            </DidomiTableTd>
            <DidomiTableTd>
              <DateTableCell date={deployment.deployed_at} />
            </DidomiTableTd>
            <DidomiTableTd>
              <DidomiListShortener items={deployment.targets} />
            </DidomiTableTd>
            <DidomiTableTd>
              <div className="leading-4">{deployment.deployed_by_name}</div>
              <div className="text-neutral-gray-6 text-row-secondary leading-4">
                {deployment.deployed_by}
              </div>
            </DidomiTableTd>

            {/* Actions Column */}
            <DidomiTableTd cellAlign="right">
              <DeploymentTableActions
                showNotes={!!deployment.notice_deployment?.message}
                onViewNotesClicked={() => onViewNotesClicked(deployment)}
                onDownloadJsonClicked={() => onDownloadJsonClicked(deployment.id)}
                onOpenPreviewClicked={() => onPreviewClicked(deployment)}
              />
            </DidomiTableTd>
          </DidomiTableRow>
        ))}
      </div>
    </DidomiTableBody>
  </DidomiTable>
);
