// Calculation is based on the article: https://www.fullstacklabs.co/blog/creating-an-svg-gauge-component-from-scratch
const radius = 21;
const strokeWidth = 4;
const innerRadius = radius - strokeWidth / 2;

const circumference = innerRadius * 2 * Math.PI;
const size = radius * 2;
const viewBox = `0 0 ${size} ${size}`;

/*
 * Since the SVG begins its stroke at 0°(the right end of the circle) - rotate circle to start at the top.
 */
const transform = `rotate(-90, ${radius}, ${radius})`;

export const CHART_CONFIG = {
  circumference,
  innerRadius,
  radius,
  size,
  strokeWidth,
  transform,
  viewBox,
};
