import { useMemo, useState } from 'react';

interface AutocompleteStateParams<T> {
  items: T[];
  predicate: (item: T, search: string) => boolean;
}

export const useAutocompleteState = <T>({ items, predicate }: AutocompleteStateParams<T>) => {
  const [search, setSearch] = useState('');

  const filteredItems = useMemo(
    () => items.filter(item => predicate(item, search)),
    [items, search, predicate],
  );

  const handleSearch = (search: string) => {
    setSearch(search.toLowerCase());
  };

  return {
    search,
    handleSearch,
    filteredItems,
  };
};
