import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { ReportsQuota } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

/**
 * Fetch reports quota
 * */
export const useReportsQuota = (options?: UseQueryOptions<ReportsQuota>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchQuota = async (): Promise<ReportsQuota> => {
    const url = `${CONFIG.environmentConfig.apiBaseUrl}reports/consent-proofs/usage?organization_id=${organizationId}`;
    const response = await axiosWithInterceptors.get<ReportsQuota>(url);

    return response.data;
  };

  return useQuery<ReportsQuota, AxiosError>(
    QUERY_KEYS.REPORTS_QUOTA(organizationId),
    fetchQuota,
    options,
  );
};
