import React from 'react';
import { DidomiIconButton, DidomiTooltip } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { TRACKING_SELECTORS } from '@constants';

interface DeploymentTableActionsProps {
  showNotes: boolean;
  onViewNotesClicked: () => void;
  onDownloadJsonClicked: () => void;
  onOpenPreviewClicked: () => void;
}

export const DeploymentTableActions = ({
  showNotes,
  onViewNotesClicked,
  onDownloadJsonClicked,
  onOpenPreviewClicked,
}: DeploymentTableActionsProps) => {
  return (
    <div className="flex gap-x-xxxs p-1">
      <DidomiTooltip
        className={tx({ '!invisible': !showNotes })}
        content="Version's notes"
        placement="top"
        distance="xs"
      >
        <DidomiIconButton
          icon="notes"
          variant="rounded"
          data-cy="view-notes"
          data-tracking={TRACKING_SELECTORS.versions.openNotesButton}
          onClick={onViewNotesClicked}
        />
      </DidomiTooltip>

      <DidomiTooltip content="Download JSON" placement="top" distance="xs">
        <DidomiIconButton
          icon="download"
          variant="rounded"
          data-cy="download-json"
          data-tracking={TRACKING_SELECTORS.versions.downloadButton}
          onClick={onDownloadJsonClicked}
        />
      </DidomiTooltip>

      <DidomiTooltip content="Preview" placement="top" distance="xs">
        <DidomiIconButton
          icon="preview"
          variant="rounded"
          data-cy="open-preview"
          data-tracking={TRACKING_SELECTORS.versions.previewButton}
          onClick={onOpenPreviewClicked}
        />
      </DidomiTooltip>
    </div>
  );
};
