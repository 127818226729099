import { useSnackbar } from '@didomi/utility-react';
import { ExtendedConsentProofReport } from '@types';
import { extendAllReports, saveAsFile } from '@utils';
import { useConsentProofsReports } from './useConsentProofsReports.hook';
import { useDeleteReport } from './useDeleteReport.hook';
import { useProofReportCsv } from './useProofReportCsv.hook';
import { useReportsQuota } from './useReportsQuota.hook';
import { useRequestReport } from './useRequestReport.hook';

const SNACKBAR_DURATION = 5000;

export const useConsentProofsApi = () => {
  const { data: extendedReports, isLoading: isReportsLoading } = useConsentProofsReports({
    select: extendAllReports,
  });
  const { data: reportsQuota, isLoading: isReportsQuotaLoading } = useReportsQuota();
  const { displaySnackbar } = useSnackbar();

  const handleApiError = err => {
    const message = err?.response?.data?.message || `An error occurred while saving the changes`;
    displaySnackbar(message, { variant: 'error', duration: SNACKBAR_DURATION });
  };

  // Mutations
  const { mutate: deleteReport, isLoading: isDeleting } = useDeleteReport({
    onSuccess: () => displaySnackbar(`The report generation has been stopped.`),
    onError: handleApiError,
  });

  const { mutate: requestReport, isLoading: isRequestingReport } = useRequestReport({
    onSuccess: () =>
      displaySnackbar(
        `The report will be ready within the next 2 hours, you will receive an email notification.`,
        { icon: 'check', duration: SNACKBAR_DURATION },
      ),
    onError: handleApiError,
  });

  const { mutate: downloadProofReportCsv } = useProofReportCsv({
    onMutate: () => displaySnackbar('CSV extraction in progress…'),
    onSuccess: ({ url, fileName }) => {
      saveAsFile(url, fileName);
      displaySnackbar('Extraction successful…');
    },
    onError: handleApiError,
  });

  const isLoading = isReportsLoading || isReportsQuotaLoading;
  const isSaving = isDeleting || isRequestingReport;

  return {
    reports: extendedReports as ExtendedConsentProofReport[],
    reportsQuota,
    isLoading,
    isSaving,
    deleteReport,
    requestReport,
    downloadProofReportCsv,
  };
};
