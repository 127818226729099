import { RegulationConfigAggregator } from '@didomi/cmp-generator';
import { NoticeConfigV2 } from '@didomi/cmp-generator/dist/types/types/notice-config-v2.type';
import { isActiveDefaultRegulation } from './isActiveDefaultRegulation';

// Transform notice config into suitable format for the SDK generator
// Returns aggregated notice config for provided regulation or root notice config if no regulation config is found
export const getNoticeConfigForGenerator = (deployment: NoticeConfigV2, regulationId: string) => {
  const targetNoticeRegulationConfig = deployment?.regulation_configurations?.find(
    regConfig => regConfig.regulation_id === regulationId && isActiveDefaultRegulation(regConfig),
  );

  if (targetNoticeRegulationConfig) {
    return RegulationConfigAggregator.constructGeneratorInput(
      deployment,
      targetNoticeRegulationConfig,
    );
  } else {
    // If no regulation config is found, return the deployment as is
    // `created_at` and `updated_at` are set to undefined to bypass the validation issue
    return { ...deployment, created_at: undefined, updated_at: undefined };
  }
};
