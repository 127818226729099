import { ConsentProofReport } from '@types';

export const INITIAL_TABLE_STATE = {
  defaultSorting: { field: 'created_at', dir: 'desc' },
  defaultLimit: 10,
} as const;

export type DialogState = {
  type: 'delete' | null;
  context: ConsentProofReport | null;
};

export const DIALOG_CLOSED_STATE = {
  type: null,
  context: null,
};
