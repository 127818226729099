import { getCounterText } from '@didomi/helpers';
import { MS_IN_HOUR, MS_IN_MIN, MS_IN_DAY } from '@constants';

interface TimeGranularity {
  min: number;
  max: number;
  label: string;
  msInUnit: number;
}

// Time granularity for every label - minute/hour/day
const timeGranularityConfig: TimeGranularity[] = [
  // 'minute' - max: 59min
  { min: 0, max: MS_IN_HOUR - MS_IN_MIN, label: 'minute', msInUnit: MS_IN_MIN },
  // 'hour' - min: 59m + 1ms, max: 23h
  {
    min: MS_IN_HOUR - MS_IN_MIN + 1,
    max: MS_IN_DAY - MS_IN_HOUR,
    label: 'hour',
    msInUnit: MS_IN_HOUR,
  },
  // 'day' - min: 23h + 1ms
  { min: MS_IN_DAY - MS_IN_HOUR + 1, max: Infinity, label: 'day', msInUnit: MS_IN_DAY },
];

const matchGranularity =
  (ms: number) =>
  ({ min, max }: TimeGranularity) => {
    return ms >= min && ms <= max;
  };

/**
 * Calculates the time left until the specified end date.
 * @param endDate - The end date in string format.
 * @returns The formatted time left as a string.
 * Possible results: '', '1 minute', 'n minutes', '1 hour', 'n hours', '1 day', 'n days'
 */
export const getTimeLeft = (endDate: string): string => {
  const msFromNow = new Date(endDate).getTime() - Date.now();

  if (msFromNow <= 0) return '';

  const timeGranularity = timeGranularityConfig.find(matchGranularity(msFromNow));
  const numberOfUnits = Math.ceil(msFromNow / timeGranularity.msInUnit);

  return getCounterText(numberOfUnits, timeGranularity.label);
};
