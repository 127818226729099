import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { ConsentProofReport } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

export const useDeleteReport = (
  options?: UseMutationOptions<
    AxiosResponse<ConsentProofReport>,
    AxiosError<{ message: string }>,
    string
  >,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const deleteReportFromCache = (reportId: string) => {
    queryClient.setQueryData<ConsentProofReport[]>(
      QUERY_KEYS.CONSENT_PROOFS_REPORTS(organizationId),
      prevReports => prevReports.filter(report => report.id !== reportId),
    );
    // Invalidate the reports-quota query to refetch the up to date information
    queryClient.invalidateQueries(['reports-quota', organizationId]);
  };

  return useMutation(
    async (reportId: string) => {
      return axiosWithInterceptors.delete(
        `${CONFIG.environmentConfig.apiBaseUrl}reports/consent-proofs/reports/${reportId}?organization_id=${organizationId}`,
      );
    },
    {
      ...options,
      onSuccess: (response: AxiosResponse<ConsentProofReport>, removedReportId, context) => {
        deleteReportFromCache(removedReportId);
        options?.onSuccess?.(response, removedReportId, context);
      },
    },
  );
};
