import { Navigate } from 'react-router-dom';
import React from 'react';
import { useHasAccessPolicies } from '@didomi/utility-react';

interface ProtectedRouteProps {
  requiredAccessPolicies: readonly string[];
  redirectPath: string;
  children: React.JSX.Element;
}

export const ProtectedRoute = ({
  requiredAccessPolicies,
  redirectPath,
  children,
}: ProtectedRouteProps) => {
  const { hasAccess, isLoading } = useHasAccessPolicies(requiredAccessPolicies);

  if (isLoading) {
    return null;
  }

  return hasAccess ? children : <Navigate to={redirectPath} />;
};
