import React from 'react';
import {
  DidomiButton,
  DidomiModal,
  DidomiModalActions,
  DidomiModalHeader,
} from '@didomi/ui-atoms-react';
import { ExtendedNoticeDeployment } from '@types';

type NoticeDeploymentNotesModalProps = {
  isOpen: boolean;
  deployment: ExtendedNoticeDeployment;
  onClose: () => void;
};

export const NoticeDeploymentNotesModal = ({
  isOpen,
  deployment,
  onClose,
}: NoticeDeploymentNotesModalProps) => (
  <DidomiModal isOpen={isOpen} permanent closable>
    <DidomiModalHeader
      modalTitle={`Version ID: ${deployment?.id}`}
      modalSubTitle={deployment?.notice_name}
      modalDescription={deployment?.notice_deployment?.message}
    />
    <DidomiModalActions>
      <DidomiButton onClick={onClose} data-cy="close-modal">
        Close
      </DidomiButton>
    </DidomiModalActions>
  </DidomiModal>
);
