import { useActiveOrganization } from '@didomi/utility-react';
import { UseMutationOptions, useMutation } from 'react-query';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

interface ReportDownloadData {
  url: string;
  fileName: string;
}

export const useProofReportCsv = (
  options?: UseMutationOptions<ReportDownloadData, Error, string>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const getCsvReport = async (reportId: string) => {
    const url = `${CONFIG.environmentConfig.apiBaseUrl}reports/consent-proofs/reports/${reportId}/links?organization_id=${organizationId}`;
    const response = await axiosWithInterceptors.post<{ url: string }>(url);

    return { url: response.data.url, fileName: `${reportId}.csv` };
  };

  return useMutation(getCsvReport, options);
};
