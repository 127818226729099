import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { tx } from '@twind/core';
import { SPA_NAME } from '@constants';

export const MobileNoPreviewPlaceholder = () => {
  const ASSETS_URL = useSPAAssetsUrl(SPA_NAME);

  return (
    <div
      className={tx(
        'h-full w-full flex flex-col justify-center items-center text-center text-primary-blue-6 p-m',
      )}
    >
      <img
        src={`${ASSETS_URL}/assets/mobile-preview-not-available.png`}
        alt="Preview not available"
        width={70}
        height={86}
      />
      <h3 className={tx('h3 mt-s mb-xxxs')}>
        The overview of the mobile APP cannot be displayed because the Didomi console does not use
        the mobile SDK.
      </h3>
    </div>
  );
};
