// Helper function to create DOM elements
export const createDomElement = (
  tagName: string,
  attributes: Record<string, string> = {},
): HTMLElement | HTMLInputElement => {
  const element = document.createElement(tagName);

  Object.keys(attributes).forEach((key: string) => {
    element.setAttribute(key, attributes[key]);
  });

  return element;
};
