import React from 'react';
import { SortConfig } from '@didomi/helpers';
import {
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableRow,
  DidomiTableTd,
  DidomiTableTh,
} from '@didomi/ui-atoms-react';
import { ConsentProofReport, ExtendedConsentProofReport, ReportStatus } from '@types';
import { getTimeLeft, truncateId } from '@utils';
import { CopyWithSnackbar } from '../CopyWithSnackbar/CopyWithSnackbar';
import { DateTableCell } from '../DateTableCell/DateTableCell';
import { ReportsTableActions } from '../ReportsTableActions/ReportsTableActions';
import { ReportStatusCell } from '../ReportStatusCell/ReportStatusCell';

interface ConsentProofsReportsTableProps {
  className?: string;
  hasEditAccess: boolean;
  reports: ExtendedConsentProofReport[];
  sortConfig: SortConfig;
  onSortChange: (sortConfig: CustomEvent<{ sortId: string; direction: 'asc' | 'desc' }>) => void;
  onCancelReportClicked: (report: ConsentProofReport) => void;
  onDownloadCsvClicked: (report: ConsentProofReport) => void;
}

export const ConsentProofsReportsTable = ({
  className,
  hasEditAccess,
  reports,
  sortConfig,
  onSortChange,
  onCancelReportClicked,
  onDownloadCsvClicked,
}: ConsentProofsReportsTableProps) => {
  return (
    <DidomiTable
      className={className}
      sortable
      sortBy={sortConfig.field}
      sortDirection={sortConfig.dir}
      onSortChange={onSortChange}
    >
      <DidomiTableHeading>
        <DidomiTableHeaderRow>
          <DidomiTableTh data-cy="user-id-th" sortId="user_id">
            USER ID
          </DidomiTableTh>
          <DidomiTableTh sortId="org_user_id">ORGANIZATION USER ID</DidomiTableTh>
          <DidomiTableTh sortId="created_at">DATE OF REPORT</DidomiTableTh>
          <DidomiTableTh sortId="status">STATUS</DidomiTableTh>
          <DidomiTableTh sortId="expires_at">REPORT DELETED IN</DidomiTableTh>

          {/* Actions Column */}
          {<DidomiTableTh></DidomiTableTh>}
        </DidomiTableHeaderRow>
      </DidomiTableHeading>

      <DidomiTableBody>
        {/** There is a bug in the ui-library that breaks re-rendering the rows dynamically - wrapping the content in a div fixes the issue */}
        <div>
          {reports.map(report => (
            <DidomiTableRow key={report.id}>
              <DidomiTableTd data-cy="report-user-id-td">
                <CopyWithSnackbar text={report.user_id}>
                  {truncateId(report.user_id)}
                </CopyWithSnackbar>
              </DidomiTableTd>
              <DidomiTableTd>
                <CopyWithSnackbar text={report.org_user_id}>{report.org_user_id}</CopyWithSnackbar>
              </DidomiTableTd>
              <DidomiTableTd>
                <DateTableCell date={report.created_at} />
              </DidomiTableTd>
              <DidomiTableTd>
                <ReportStatusCell status={report.status} />
              </DidomiTableTd>
              <DidomiTableTd>
                {report.status !== ReportStatus.GENERATING ? getTimeLeft(report.expires_at) : ''}
              </DidomiTableTd>

              {/* Actions Column */}
              <DidomiTableTd cellAlign="right">
                <ReportsTableActions
                  status={report.status}
                  isCancelAllowed={hasEditAccess}
                  onCancelClicked={() => onCancelReportClicked(report)}
                  onDownloadCsvClicked={() => onDownloadCsvClicked(report)}
                />
              </DidomiTableTd>
            </DidomiTableRow>
          ))}
        </div>
      </DidomiTableBody>
    </DidomiTable>
  );
};
