import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { ConsentProofReport, ReportSearchParams } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

export const useRequestReport = (
  options?: UseMutationOptions<
    AxiosResponse<ConsentProofReport>,
    AxiosError<{ message: string }>,
    ReportSearchParams
  >,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const addReportToCache = (report: ConsentProofReport) => {
    queryClient.setQueryData<ConsentProofReport[]>(
      QUERY_KEYS.CONSENT_PROOFS_REPORTS(organizationId),
      prevReports => [...prevReports, report],
    );

    // Invalidate the reports-quota query to refetch the up to date information
    queryClient.invalidateQueries(QUERY_KEYS.REPORTS_QUOTA(organizationId));
  };

  return useMutation(
    async (reportParams: ReportSearchParams) => {
      return axiosWithInterceptors.post(
        `${CONFIG.environmentConfig.apiBaseUrl}reports/consent-proofs/reports?organization_id=${organizationId}`,
        reportParams,
      );
    },
    {
      ...options,
      onSuccess: (response: AxiosResponse<ConsentProofReport>, reportParams, context) => {
        addReportToCache(response.data);
        options?.onSuccess?.(response, reportParams, context);
      },
    },
  );
};
