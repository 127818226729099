import React from 'react';
import { DidomiModal, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import {
  ConsentNoticePreviewToolbar,
  MobileNoPreviewPlaceholder,
  NoticePreviewSidebar,
} from '@components';
import { useNoticeDeploymentById, useNoticePreviewIframe, usePreviewRegulationIds } from '@hooks';
import { ExtendedNoticeDeployment } from '@types';
import { useNoticeDeploymentSdkConfig } from 'hooks/useNoticeDeploymentSdkConfig.hook';

type ConsentNoticePreviewModalProps = {
  isOpen: boolean;
  noticeDeployment: ExtendedNoticeDeployment;
  onClose: () => void;
};

export const ConsentNoticePreviewModal = ({
  isOpen,
  noticeDeployment,
  onClose,
}: ConsentNoticePreviewModalProps) => {
  const isMobile = noticeDeployment?.platform === 'app';
  const { data: deploymentConfig, isLoading: isDeploymentLoading } = useNoticeDeploymentById(
    noticeDeployment?.id,
    { enabled: !isMobile && !!noticeDeployment },
  );
  const { regulationId, setRegulationId, availableRegulationsIds } =
    usePreviewRegulationIds(deploymentConfig);
  const { data: sdkData, isLoading: isSdkDataLoading } = useNoticeDeploymentSdkConfig(
    deploymentConfig,
    regulationId,
  );
  const previewIframe = useNoticePreviewIframe(sdkData);

  const handleClose = () => {
    onClose();
    previewIframe.reset();
  };

  const isLoading = isDeploymentLoading || isSdkDataLoading;

  return (
    <>
      {isOpen && (
        <DidomiModal variant="fullscreen" isOpen={true} permanent>
          <DidomiSkeleton className={tx('w-full h-full')} variant="layout" isLoading={isLoading}>
            <div className={tx('w-full h-full flex bg-squared')}>
              <NoticePreviewSidebar deployment={noticeDeployment} />

              <div className={tx('!flex flex-col gap-s w-full h-full p-xs')}>
                <ConsentNoticePreviewToolbar
                  regulationId={regulationId}
                  availableRegulationsIds={availableRegulationsIds}
                  onRegulationIdChange={setRegulationId}
                  onClose={handleClose}
                />

                <div className="h-full" data-skeleton>
                  {isMobile && <MobileNoPreviewPlaceholder />}
                  {!isMobile && (
                    <iframe
                      ref={previewIframe.iframeRef}
                      className={tx('w-full h-full')}
                      title="Notice Deployment Preview"
                      src={previewIframe.iframeSrc}
                    />
                  )}
                </div>
              </div>
            </div>
          </DidomiSkeleton>
        </DidomiModal>
      )}
    </>
  );
};
