export interface ConsentProofReport {
  id: string;
  organization_id: string;
  search_parameters: ReportSearchParams;
  created_at: string;
  created_by: string;
  created_by_type: ReportClientType;
  expires_at: string | null;
  status: ReportStatus;
}

export interface ExtendedConsentProofReport extends ConsentProofReport {
  user_id: string;
  org_user_id: string;
}

export enum ReportStatus {
  GENERATING = 'running',
  COMPLETED = 'success',
  FAILED = 'failure',
  COMPLETED_EMPTY = 'success_no_results',
}

export enum ReportClientType {
  USER = 'user',
  KEY = 'key',
  CLIENT = 'client',
}

export interface ReportSearchParams {
  user_id: string;
  user_id_type: UserIdType;
}

export enum UserIdType {
  USER_ID = 'user_id',
  ORG_USER_ID = 'organization_user_id',
}
