import React from 'react';
import { DidomiChip, DidomiIcon, DidomiTooltip } from '@didomi/ui-atoms-react';
import { ReportStatus } from '@types';

const ICONS_MAP = {
  [ReportStatus.COMPLETED]: 'check-sm',
  [ReportStatus.COMPLETED_EMPTY]: 'danger-light-sm',
  [ReportStatus.FAILED]: 'close-sm',
} as const;

const TEXT_MAP = {
  [ReportStatus.COMPLETED]: 'Completed',
  [ReportStatus.GENERATING]: 'Generating...',
  [ReportStatus.FAILED]: 'Failed',
  [ReportStatus.COMPLETED_EMPTY]: 'ID not found',
};

const COLORS_MAP = {
  [ReportStatus.COMPLETED]: 'success',
  [ReportStatus.COMPLETED_EMPTY]: 'warning',
  [ReportStatus.FAILED]: 'error',
} as const;

export const ReportStatusCell = ({ status }: { status: ReportStatus }) => {
  if (status === ReportStatus.GENERATING) {
    return (
      <DidomiTooltip
        placement="top"
        content="Most of the reports are completed within 2 hours"
        distance="xxxs"
      >
        <div>
          <span>{TEXT_MAP[status]}</span>
          <DidomiIcon name="reset-sm" className="reportStatus__generatingIcon mx-1"></DidomiIcon>
        </div>
      </DidomiTooltip>
    );
  } else {
    return (
      <DidomiChip
        leftIcon={ICONS_MAP[status] || ''}
        basicType={COLORS_MAP[status]}
        label={TEXT_MAP[status]}
      ></DidomiChip>
    );
  }
};
