const PART_LENGTH = 4;
const SEPARATOR = '...';
const MIN_ID_LENGTH = PART_LENGTH * 2 + SEPARATOR.length + 1;

/**
 * Truncates the given ID string if its length is greater than or equal to MIN_ID_LENGTH.
 * The truncated ID will have the first PART_LENGTH characters followed by "..." and the last PART_LENGTH characters.
 * If the ID length is less than MIN_ID_LENGTH, the original ID is returned.
 */
export const truncateId = (id: string): string => {
  if (id.length < MIN_ID_LENGTH) {
    return id;
  }

  const start = id.substring(0, PART_LENGTH);
  const end = id.substring(id.length - PART_LENGTH, id.length);

  return `${start}...${end}`;
};
