import React from 'react';
import {
  DidomiButton,
  DidomiSelectField,
  DidomiSelectOption,
  DidomiSelectOptions,
  DidomiTextInputField,
} from '@didomi/ui-atoms-react';
import { Formik, Form } from 'formik';
import { TRACKING_SELECTORS, USER_ID_TYPE_TO_TEXT } from '@constants';
import { ReportSearchParams, UserIdType } from '@types';
import { reportFormValidator } from '@utils';

const INITIAL_VALUES: ReportSearchParams = {
  user_id: '',
  user_id_type: UserIdType.USER_ID,
};

interface RequestReportFormProps {
  disabled?: boolean;
  onSubmit: (v: ReportSearchParams) => void;
}

export const RequestReportForm = ({ disabled, onSubmit }: RequestReportFormProps) => {
  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validate={reportFormValidator}
      onSubmit={(v: ReportSearchParams, { resetForm }) => {
        onSubmit(v);
        resetForm();
      }}
    >
      <Form className="flex gap-xs">
        <DidomiTextInputField
          className="basis-[300px]"
          name="user_id"
          placeholder="Paste the ID here"
          data-cy="report-user-id-input"
          disabled={disabled}
        />

        <DidomiSelectField
          className="basis-[300px]"
          name="user_id_type"
          data-cy="report-id-type"
          disabled={disabled}
        >
          <DidomiSelectOptions>
            <DidomiSelectOption
              value={UserIdType.USER_ID}
              label={USER_ID_TYPE_TO_TEXT[UserIdType.USER_ID]}
            />
            <DidomiSelectOption
              value={UserIdType.ORG_USER_ID}
              label={USER_ID_TYPE_TO_TEXT[UserIdType.ORG_USER_ID]}
            />
          </DidomiSelectOptions>
        </DidomiSelectField>

        <DidomiButton
          className="shrink-0"
          type="submit"
          data-cy="request-report-cta"
          data-tracking={TRACKING_SELECTORS.proofs.generateReportBtn}
        >
          Generate Report
        </DidomiButton>
      </Form>
    </Formik>
  );
};
