import { UseMutationOptions, useMutation } from 'react-query';
import { buildUrl, extractFileNameFromContentDisposition } from '@utils';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

interface DeploymentDownloadData {
  data: string;
  fileName: string;
}

/**
 * Fetch notice deployments data for CSV download
 * */
export const useNoticeDeploymentsCsv = (
  options?: UseMutationOptions<DeploymentDownloadData, Error, string[]>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const loadDeploymentsCsv = async (deploymentIds: string[]) => {
    const params = {
      id: deploymentIds,
      format: 'csv',
    };
    const url = buildUrl(
      `${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/configs?download`,
      params,
    );
    const response = await axiosWithInterceptors.get<string>(url);
    const fileName = extractFileNameFromContentDisposition(response.headers['content-disposition']);

    return { data: response.data, fileName };
  };

  return useMutation(loadDeploymentsCsv, options);
};
