import { UseMutationOptions, useMutation } from 'react-query';
import { NoticeDeployment } from '@types';
import { extractFileNameFromContentDisposition } from '@utils';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

interface DeploymentDownloadData {
  data: NoticeDeployment;
  fileName: string;
}

/**
 * Fetch notice deployment data for JSON download
 * */
export const useNoticeDeploymentJson = (
  options?: UseMutationOptions<DeploymentDownloadData, Error, string>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const loadDeploymentJson = async (deploymentId: string) => {
    const url = `${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/configs/${deploymentId}?download`;
    const response = await axiosWithInterceptors.get<NoticeDeployment>(url);
    const fileName = extractFileNameFromContentDisposition(response.headers['content-disposition']);

    return { data: response.data, fileName };
  };

  return useMutation<DeploymentDownloadData, Error, string>(loadDeploymentJson, options);
};
