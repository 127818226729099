import React from 'react';
import { formatDate, formatTime } from '@utils';

interface DateTableCellProps {
  date: string;
}

export const DateTableCell = ({ date }: DateTableCellProps) => {
  return (
    <div>
      <div className="leading-4">{formatDate(date)}</div>
      <div className="text-neutral-gray-6 text-row-secondary leading-4">{formatTime(date)}</div>
    </div>
  );
};
