import { MimeType } from '@types';
import { saveAsFile } from './saveAsFile';

/**
 * Convert data into the local file url and save it to the file
 */
export const downloadDataAsFile = (
  fileData: Record<string, any> | string,
  fileName: string,
  mimeType: MimeType,
): void => {
  const stringifiedData = typeof fileData === 'string' ? fileData : JSON.stringify(fileData);
  const file = new Blob([stringifiedData], { type: mimeType });
  const fileUrl = URL.createObjectURL(file);

  saveAsFile(fileUrl, fileName);

  URL.revokeObjectURL(fileUrl);
};
