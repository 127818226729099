import React from 'react';
import { tx } from '@twind/core';
import { GaugeChart } from '../GaugeChart/GaugeChart';

interface QuotaCounterProps {
  className?: string;
  currentValue: number;
  maxValue: number;
  quotaLabel: string;
  quotaReachedLabel: string;
}

export const QuotaCounter = ({
  className,
  currentValue,
  maxValue,
  quotaLabel,
  quotaReachedLabel,
}: QuotaCounterProps) => {
  const isQuotaReached = currentValue >= maxValue;
  const titleText = isQuotaReached ? quotaReachedLabel : quotaLabel;

  return (
    <section className={tx(className, 'flex gap-xxs p-xxs pl-xs rounded-lg bg-light-blue')}>
      <section className="text-right text-xs">
        <div className="font-semibold mb-0.5">{titleText}</div>
        <div data-cy="quota-text">
          {currentValue} of {maxValue}
        </div>
      </section>

      <GaugeChart currentValue={currentValue} maxValue={maxValue} />
    </section>
  );
};
