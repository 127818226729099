const RANGE_STRING_REGEX = /^[\d/]{8,10}( to [\d/]{8,10})?$/;

/**
 * Parse date range string
 *
 * @param dateRangeString {string} - date range string in the format "Start to End" - "7/15/2021 to 7/17/2021" OR "7/15/2021" ("month/day/year")
 * @return - tuple of start and end date(end of the day) timestamps or undefined if the input format is incorrect.
 */
export const getDatesRange = (
  dateRangeString: string | undefined,
): [number, number] | undefined => {
  let result: [number, number];

  if (typeof dateRangeString === 'string' && RANGE_STRING_REGEX.test(dateRangeString)) {
    const matches = dateRangeString.match(/(?<from>[\d/]+)( to (?<to>[\d/]+))?/);
    const { from, to } = matches.groups;

    // If no end date is provided, set it to start date end of the day
    const end = new Date(to || from);
    end.setHours(23, 59, 59, 999);

    result = [new Date(from).getTime(), end.getTime()];
  }

  return result;
};
