import { ExtendedNoticeDeployment, Notice, NoticeDeployment } from '@types';

const getNoticeIdToNameMap = (notices: Notice[]): Map<string, string> => {
  const noticeMap = new Map();
  notices.forEach(notice => noticeMap.set(notice.id, notice.name));
  return noticeMap;
};

export const joinNoticeDeploymentsWithNotices = (
  deployments: NoticeDeployment[] = [],
  notices: Notice[] = [],
): ExtendedNoticeDeployment[] => {
  const noticeIdToNameMap = getNoticeIdToNameMap(notices);

  return deployments.map(deployment => ({
    ...deployment,
    notice_name: noticeIdToNameMap.get(deployment.notice_id),
  }));
};
