import { createDomElement } from './createDomElement';

/**
 * Create hidden anchor element with file source and click on it to trigger browser download
 */
export const saveAsFile = (source: string, fileName: string) => {
  const element = createDomElement('a', {
    href: source,
    download: fileName,
    style: 'display: none;',
  });

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
