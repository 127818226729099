import { PATTERNS } from '@constants';
import { ReportSearchParams, UserIdType } from '@types';

export const reportFormValidator = (values: ReportSearchParams) => {
  if (!values.user_id) {
    return { user_id: 'Required' };
  } else if (
    values.user_id_type === UserIdType.USER_ID &&
    PATTERNS.UUID.test(values.user_id) === false
  ) {
    return { user_id: 'Please enter a valid User ID' };
  }
};
