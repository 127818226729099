import { NoticeConfigV2 } from '@didomi/cmp-generator/dist/types/types/notice-config-v2.type';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { NoticeDeployment } from '@types';
import { getNoticeConfigForGenerator } from '@utils';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

/**
 * Fetch generated SDK config for a notice deployment
 * */
export const useNoticeDeploymentSdkConfig = (
  deployment: NoticeConfigV2,
  regulationId: string,
  options?: UseQueryOptions<NoticeDeployment>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchDeploymentSdkConfig = async (): Promise<NoticeDeployment> => {
    const url = `${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/generators/sdk`;
    const configForGenerator = getNoticeConfigForGenerator(deployment, regulationId);

    const response = await axiosWithInterceptors.post<NoticeDeployment>(url, configForGenerator);

    return response.data;
  };

  return useQuery<NoticeDeployment, AxiosError>(
    QUERY_KEYS.DEPLOYMENT_SDK_CONFIG(deployment?.id, regulationId),
    fetchDeploymentSdkConfig,
    {
      ...options,
      enabled: !!deployment,
    },
  );
};
