import { PaginatedResponse } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { Notice } from '@types';
import { buildUrl } from '@utils';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

/**
 * Fetch notices
 * */
export const useNotices = (options?: UseQueryOptions<Notice[]>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchNotices = async (): Promise<Notice[]> => {
    const params = {
      $select: ['id', 'name'],
      organization_id: organizationId,
      $limit: 5000,
    };
    const url = buildUrl(`${CONFIG.environmentConfig.apiBaseUrl}widgets/notices`, params);
    const response = await axiosWithInterceptors.get<PaginatedResponse<Notice>>(url);

    return response.data.data;
  };

  return useQuery<Notice[], AxiosError>(QUERY_KEYS.NOTICES(organizationId), fetchNotices, options);
};
