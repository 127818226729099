// Custom error class for API errors
export class ApiError extends Error {
  request_metadata;

  constructor(error) {
    super(error.message);
    this.name = 'ApiError';

    this.request_metadata = {
      method: error.config?.method?.toUpperCase(),
      url: error.config?.url,
      error_message: error.response?.data?.message,
    };
  }
}
