import React, { useMemo } from 'react';
import { CHART_CONFIG } from './GaugeChartConfig';

interface GaugeChartProps {
  currentValue: number;
  maxValue: number;
}

export const GaugeChart = ({ currentValue, maxValue }: GaugeChartProps) => {
  const isMaxValueReached = currentValue >= maxValue;

  const filledOffset = useMemo(() => {
    const percents = (currentValue / maxValue) * 100;
    const percentNormalized = Math.min(percents, 100);

    return CHART_CONFIG.circumference - (percentNormalized / 100) * CHART_CONFIG.circumference;
  }, [currentValue, maxValue]);

  return (
    <svg
      aria-hidden="true"
      width={CHART_CONFIG.size}
      height={CHART_CONFIG.size}
      viewBox={CHART_CONFIG.viewBox}
    >
      <defs>
        {/* Chart inset shadow */}
        <filter
          id="inset-shadow-base"
          x="0"
          y="0"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.36035" />
          <feGaussianBlur stdDeviation="1.68018" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" />
        </filter>
      </defs>

      {/* Base */}
      <circle
        filter="url(#inset-shadow-base)"
        fill="none"
        cx={CHART_CONFIG.radius}
        cy={CHART_CONFIG.radius}
        r={CHART_CONFIG.innerRadius}
        strokeWidth={CHART_CONFIG.strokeWidth}
        stroke="#EFF7FB"
      />

      {/* Percents */}
      <circle
        data-testid="percents-circle"
        filter="url(#inset-shadow-base)"
        fill="none"
        data-max-reached={isMaxValueReached}
        cx={CHART_CONFIG.radius}
        cy={CHART_CONFIG.radius}
        r={CHART_CONFIG.innerRadius}
        style={{ stroke: isMaxValueReached ? 'var(--primary-pink-4)' : 'var(--secondary-teal-3)' }}
        strokeWidth={CHART_CONFIG.strokeWidth}
        strokeDasharray={CHART_CONFIG.circumference}
        strokeDashoffset={filledOffset}
        transform={CHART_CONFIG.transform}
      />

      {/* Value */}
      <text
        className="text-xs font-semibold fill-current"
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {currentValue}
      </text>
    </svg>
  );
};
