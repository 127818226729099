import { NoticeConfigV2 } from '@didomi/cmp-generator/dist/types/types/notice-config-v2.type';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

/**
 * Fetch notice deployment by id
 * */
export const useNoticeDeploymentById = (
  deploymentId: string,
  options?: UseQueryOptions<NoticeConfigV2>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchDeployment = async (): Promise<NoticeConfigV2> => {
    const url = `${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/configs/${deploymentId}`;
    const response = await axiosWithInterceptors.get<NoticeConfigV2>(url, { headers: { v: 2 } });

    return response.data;
  };

  return useQuery<NoticeConfigV2, AxiosError>(
    QUERY_KEYS.DEPLOYMENT_BY_ID(deploymentId),
    fetchDeployment,
    options,
  );
};
