import { useEffect, useRef, useState } from 'react';
import { useEventListener, useSPAAssetsUrl } from '@didomi/helpers-react';
import { SPA_NAME } from '@constants';
import { NoticeDeployment } from '@types';

// Dedicated hook to handle the state and communication with the notice preview iframe
export const useNoticePreviewIframe = (sdkData: NoticeDeployment) => {
  const iframeRef = useRef(null);
  // This state is used to know when the iframe is loaded and ready to receive the sdkData
  const [isIframeReady, setIframeReady] = useState(false);

  const ASSETS_URL = useSPAAssetsUrl(SPA_NAME);
  const previewHtml = CONFIG.production ? 'preview.html' : 'preview-staging.html';
  const iframeSrc = `${ASSETS_URL}/assets/${previewHtml}`;

  useEventListener('message', (e: any) => {
    if (e.data.type === 'iframe-ready') {
      setIframeReady(true);
    }
  });

  useEffect(() => {
    // Send the sdkData to the iframe once it's ready
    if (sdkData && isIframeReady) {
      iframeRef.current.contentWindow.postMessage(
        { type: 'didomiConfig', payload: { sdkData } },
        '*',
      );
    }
  }, [sdkData, iframeRef, isIframeReady]);

  const reset = () => {
    setIframeReady(false);
  };

  return { iframeRef, isIframeReady, iframeSrc, reset };
};
