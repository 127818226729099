import React from 'react';
import { DidomiCopyText } from '@didomi/ui-atoms-react';
import { useSnackbar } from '@didomi/utility-react';

interface CopyWithSnackbarProps {
  text: string;
  children: React.ReactNode;
}

export const CopyWithSnackbar = ({ text, children }: CopyWithSnackbarProps) => {
  const { displaySnackbar } = useSnackbar();

  return (
    <DidomiCopyText
      text={text}
      onTextCopied={() => displaySnackbar('Copied to clipboard', { icon: 'check' })}
    >
      {children}
    </DidomiCopyText>
  );
};
