import React, { useMemo } from 'react';
import {
  DidomiAutocompleteField,
  DidomiDatepickerField,
  DidomiFiltersContainer,
  DidomiSelectField,
  DidomiSelectOption,
  DidomiSelectOptions,
  DidomiTextInputField,
} from '@didomi/ui-atoms-react';
import { Form, Formik } from 'formik';
import { TRACKING_SELECTORS } from '@constants';
import { useAutocompleteState } from '@hooks';
import { ExtendedNoticeDeployment, NoticeDeploymentsFilters } from '@types';
import {
  autocompleteOptionPredicate,
  autocompletePlainPredicate,
  getDatesRange,
  getDeploymentsFilteringOptions,
} from '@utils';

const INITIAL_VALUES = {
  notices: [],
  platforms: [],
  users: [],
  publishDate: null,
  versionId: '',
  targets: [],
};

interface DeploymentsFiltersProps {
  className?: string;
  deployments: ExtendedNoticeDeployment[];
  onFiltersChange: (filters: NoticeDeploymentsFilters) => void;
}

export const DeploymentsFilters = ({
  className,
  deployments = [],
  onFiltersChange,
}: DeploymentsFiltersProps) => {
  const filteringOptions = useMemo(
    () => getDeploymentsFilteringOptions(deployments),
    [deployments],
  );

  const noticeAutocomplete = useAutocompleteState({
    items: filteringOptions.notices,
    predicate: autocompleteOptionPredicate,
  });

  const usersAutocomplete = useAutocompleteState({
    items: filteringOptions.users,
    predicate: autocompleteOptionPredicate,
  });

  const targetsAutocomplete = useAutocompleteState({
    items: filteringOptions.targets,
    predicate: autocompletePlainPredicate,
  });

  const handleApplyFilters = (values: typeof INITIAL_VALUES) => {
    const publishDate = values.publishDate ? getDatesRange(values.publishDate) : undefined;
    onFiltersChange({ ...values, publishDate });
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={handleApplyFilters}
      onReset={() => onFiltersChange(INITIAL_VALUES)}
    >
      {({ dirty, submitCount, submitForm, resetForm }) => (
        <DidomiFiltersContainer
          className={className}
          // Allow filters to be applied if the form is dirty or if it has been submitted(filters has been changes) before
          formIsDirty={dirty || submitCount > 0}
          data-tracking={TRACKING_SELECTORS.versions.filter}
          onApplyClicked={submitForm}
          onResetClicked={() => resetForm()}
        >
          <Form className="grid gap-x-l gap-y-xxs grid-cols-fill-300">
            {/* Notice */}
            {filteringOptions.notices.length !== 0 && (
              <DidomiAutocompleteField
                name="notices"
                label="Notice"
                multi
                placeholder="Choose a notice by name or ID"
                data-cy="notice-filter"
                onSearchChange={e => noticeAutocomplete.handleSearch(e.detail)}
              >
                <DidomiSelectOptions>
                  <DidomiSelectOption label="Reset" variant="empty"></DidomiSelectOption>
                  {noticeAutocomplete.filteredItems.map(notice => (
                    <DidomiSelectOption
                      key={notice.id}
                      label={notice.name as any}
                      secondaryLabel={notice.id}
                      value={notice.id}
                      data-cy="select-option"
                    ></DidomiSelectOption>
                  ))}
                </DidomiSelectOptions>
              </DidomiAutocompleteField>
            )}

            {/* Platform */}
            <DidomiSelectField
              name="platforms"
              label="Platform"
              placeholder="Choose a notice type"
              multi
              data-cy="platform-filter"
            >
              <DidomiSelectOptions>
                <DidomiSelectOption label="Reset" variant="empty"></DidomiSelectOption>
                <DidomiSelectOption
                  label="AMP Website"
                  value="amp"
                  icon="amp"
                  data-cy="select-option"
                ></DidomiSelectOption>
                <DidomiSelectOption
                  label="Website"
                  value="web"
                  icon="website"
                  data-cy="select-option"
                ></DidomiSelectOption>
                <DidomiSelectOption
                  label="Mobile"
                  value="app"
                  icon="mobile"
                  data-cy="select-option"
                ></DidomiSelectOption>
                <DidomiSelectOption
                  label="Connected TV"
                  value="ctv"
                  icon="ctv"
                  data-cy="select-option"
                ></DidomiSelectOption>
              </DidomiSelectOptions>
            </DidomiSelectField>

            {/* User */}
            {filteringOptions.users.length !== 0 && (
              <DidomiAutocompleteField
                name="users"
                label="User"
                multi
                placeholder="Choose users in the list"
                data-cy="user-filter"
                onSearchChange={e => usersAutocomplete.handleSearch(e.detail)}
              >
                <DidomiSelectOptions>
                  <DidomiSelectOption label="Reset" variant="empty"></DidomiSelectOption>
                  {usersAutocomplete.filteredItems.map(user => (
                    <DidomiSelectOption
                      key={user.id}
                      label={user.name as any}
                      secondaryLabel={user.id}
                      value={user.id}
                      data-cy="select-option"
                    ></DidomiSelectOption>
                  ))}
                </DidomiSelectOptions>
              </DidomiAutocompleteField>
            )}

            {/* Publish Date */}
            <DidomiDatepickerField
              name="publishDate"
              label="Publish date"
              placeholder="Choose a date range"
              range
              dateFormat="n/j/Y"
            ></DidomiDatepickerField>

            {/* Version ID */}
            <DidomiTextInputField name="versionId" label="Version ID" data-cy="version-filter" />

            {/* Target */}
            {filteringOptions.targets.length !== 0 && (
              <DidomiAutocompleteField
                name="targets"
                label="Target"
                multi
                placeholder="Choose a domain or app ID"
                data-cy="target-filter"
                onSearchChange={e => targetsAutocomplete.handleSearch(e.detail)}
              >
                <DidomiSelectOptions>
                  <DidomiSelectOption label="Reset" variant="empty"></DidomiSelectOption>
                  {targetsAutocomplete.filteredItems.map(target => (
                    <DidomiSelectOption
                      key={target as string}
                      label={target as string}
                      value={target as string}
                      data-cy="select-option"
                    ></DidomiSelectOption>
                  ))}
                </DidomiSelectOptions>
              </DidomiAutocompleteField>
            )}
          </Form>
        </DidomiFiltersContainer>
      )}
    </Formik>
  );
};
