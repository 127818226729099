import { ExtendedNoticeDeployment } from '@types';

interface OptionsHash {
  users: Record<string, string>;
  notices: Record<string, string>;
  targets: Set<string>;
}

/* Extract filtering options from deployments
 * Get unique options for:
 * - users
 * - notices
 * - targets
 */
export const getDeploymentsFilteringOptions = (deployments: ExtendedNoticeDeployment[]) => {
  if (deployments.length === 0) return { users: [], notices: [], targets: [] };

  // Get hash of unique users, notices and targets
  const optionsHash = deployments.reduce<OptionsHash>(
    (acc, deployment) => {
      acc.users[deployment.deployed_by] = deployment.deployed_by_name;
      acc.notices[deployment.notice_id] = deployment.notice_name;
      deployment.targets?.forEach(t => acc.targets.add(t));

      return acc;
    },
    { users: {}, notices: {}, targets: new Set() },
  );

  return {
    users: Object.entries(optionsHash.users)
      .filter(([id, name]) => id && name)
      .map(([id, name]) => ({ id, name }))
      .sort((a, b) => a.name.localeCompare(b.name)),
    notices: Object.entries(optionsHash.notices)
      .filter(([id, name]) => id && name)
      .map(([id, name]) => ({ id, name }))
      .sort((a, b) => a.name.localeCompare(b.name)),
    targets: Array.from(optionsHash.targets).sort((a, b) => a.localeCompare(b)),
  };
};
