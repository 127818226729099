import React from 'react';
import {
  DidomiPreviewToolbar,
  DidomiSelect,
  DidomiSelectOptions,
  DidomiSelectOption,
  DidomiIconButton,
} from '@didomi/ui-atoms-react';

interface ConsentNoticePreviewToolbarProps {
  regulationId: string;
  availableRegulationsIds: string[];
  onRegulationIdChange: (regulationId: string) => void;
  onClose: () => void;
}

export const ConsentNoticePreviewToolbar = ({
  regulationId,
  availableRegulationsIds,
  onRegulationIdChange,
  onClose,
}: ConsentNoticePreviewToolbarProps) => {
  return (
    <DidomiPreviewToolbar>
      <div slot="left" style={{ width: '200px' }} data-skeleton>
        {!!availableRegulationsIds?.length && (
          <DidomiSelect
            variant="modern"
            value={regulationId}
            hideErrorMessage
            onValueChange={e => onRegulationIdChange(e.detail as string)}
          >
            <DidomiSelectOptions>
              {availableRegulationsIds.map(regulationId => (
                <DidomiSelectOption
                  key={regulationId}
                  value={regulationId}
                  label={regulationId.toUpperCase()}
                  leftIcon={`${regulationId}-md`}
                ></DidomiSelectOption>
              ))}
            </DidomiSelectOptions>
          </DidomiSelect>
        )}
      </div>

      <DidomiIconButton variant="rounded" slot="right" icon="close-cancel" onClick={onClose} />
    </DidomiPreviewToolbar>
  );
};
