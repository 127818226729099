export const QUERY_KEYS = {
  NOTICES: (orgId: string) => ['notices', orgId],
  DEPLOYMENTS: (orgId: string) => ['notice-deployments', orgId],
  DEPLOYMENT_BY_ID: (id: string) => ['notice-deployment', id],
  DEPLOYMENT_SDK_CONFIG: (id: string, regulationId: string) => [
    'notice-deployment-sdk',
    id,
    regulationId,
  ],
  CONSENT_PROOFS_REPORTS: (orgId: string) => ['consent-proofs-reports', orgId],
  REPORTS_QUOTA: (orgId: string) => ['reports-quota', orgId],
};
