import { useEffect, useMemo, useState } from 'react';
import { NoticeConfigV2 } from '@didomi/cmp-generator/dist/types/types/notice-config-v2.type';
import { isActiveDefaultRegulation } from '@utils';

// Custom hook to manage preview's selected regulation id and available regulations ids
export const usePreviewRegulationIds = (deploymentConfig: NoticeConfigV2) => {
  const [regulationId, setRegulationId] = useState<string>();

  const availableRegulationsIds = useMemo(() => {
    return (
      deploymentConfig?.regulation_configurations
        ?.filter(isActiveDefaultRegulation)
        .map(r => r.regulation_id) || []
    );
  }, [deploymentConfig]);

  // Set the first available regulation id as the default one
  useEffect(() => setRegulationId(availableRegulationsIds[0]), [availableRegulationsIds]);

  return { regulationId, availableRegulationsIds, setRegulationId };
};
