import React from 'react';
import { getCounterText } from '@didomi/helpers';

export const ResultsText = ({ count, noun = 'result' }) => {
  const resultsText = getCounterText(count, noun);

  return (
    <div className="text-body-small font-semibold mb-xxs" data-cy="results-counter">
      {resultsText}
    </div>
  );
};
