import { PaginatedResponse } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { ConsentProofReport } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

export const useConsentProofsReports = (options?: UseQueryOptions<ConsentProofReport[]>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchReports = async (): Promise<ConsentProofReport[]> => {
    const url = `${CONFIG.environmentConfig.apiBaseUrl}reports/consent-proofs/reports?organization_id=${organizationId}`;
    const response = await axiosWithInterceptors.get<PaginatedResponse<ConsentProofReport>>(url);

    return response.data.data;
  };

  return useQuery<ConsentProofReport[], AxiosError>(
    QUERY_KEYS.CONSENT_PROOFS_REPORTS(organizationId),
    fetchReports,
    options,
  );
};
