import { useMemo } from 'react';
import { useSnackbar } from '@didomi/utility-react';
import { MimeType } from '@types';
import { downloadDataAsFile, joinNoticeDeploymentsWithNotices } from '@utils';
import { useNoticeDeploymentJson } from './useNoticeDeploymentJson.hook';
import { useNoticeDeployments } from './useNoticeDeployments.hook';
import { useNoticeDeploymentsCsv } from './useNoticeDeploymentsCsv.hook';
import { useNotices } from './useNotices.hook';

export const useNoticeDeploymentsApi = () => {
  const { data: noticeDeployments, isLoading: isDeploymentsLoading } = useNoticeDeployments();
  const { data: notices, isLoading: isNoticesLoading } = useNotices();
  const { displaySnackbar } = useSnackbar();

  const handleApiError = err => {
    const message = err?.response?.data?.message || `An error occurred while downloading the file`;
    displaySnackbar(message, { variant: 'error', duration: 5000 });
  };

  const { mutate: downloadDeploymentJson } = useNoticeDeploymentJson({
    onMutate: () => displaySnackbar('JSON extraction in progress…'),
    onSuccess: ({ data, fileName }) => {
      downloadDataAsFile(data, fileName, MimeType.JSON);
      displaySnackbar('Extraction successful…');
    },
    onError: handleApiError,
  });

  const { mutate: downloadDeploymentsCsv } = useNoticeDeploymentsCsv({
    onMutate: () => displaySnackbar('CSV extraction in progress…'),
    onSuccess: ({ data, fileName }) => {
      downloadDataAsFile(data, fileName, MimeType.CSV);
      displaySnackbar('Extraction successful…');
    },
    onError: handleApiError,
  });

  const extendedNoticeDeployments = useMemo(
    () => joinNoticeDeploymentsWithNotices(noticeDeployments, notices),
    [noticeDeployments, notices],
  );

  const isLoading = isDeploymentsLoading || isNoticesLoading;

  return {
    extendedNoticeDeployments,
    isLoading,
    downloadDeploymentJson,
    downloadDeploymentsCsv,
  };
};
