import React from 'react';
import { DidomiIllustration } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { TEXT_PER_PLATFORM } from '@constants';
import { ExtendedNoticeDeployment } from '@types';

interface NoticePreviewSidebarProps {
  deployment: ExtendedNoticeDeployment;
}

export const NoticePreviewSidebar = ({ deployment }: NoticePreviewSidebarProps) => {
  return (
    <aside
      className={tx('bg-neutral-gray-2 h-full p-m text-left text-primary-blue-6')}
      style={{ maxWidth: '400px', minWidth: '300px' }}
      data-cy="disclaimer"
    >
      <DidomiIllustration name="disclaimer" className={tx('mb-xs')} />
      <p className={tx('italic text-body-small leading-body-small mb-m')}>
        This preview version of the consent notice cannot be used as proof of configuration at the
        time consent was given. For valid proof of configuration, please use the{' '}
        <span className={tx('text-primary-pink-4')}>CSV extract</span> or{' '}
        <span className={tx('text-primary-pink-4')}>JSON download</span> options. As this is a
        web-based viewer, the appearance of the notice may differ from the original version.
      </p>

      <div className={tx('leading-body-big')} data-cy="disclaimer-info-row" data-skeleton>
        <span className={tx('font-semibold')}>Notice:</span>{' '}
        <span>
          {deployment?.notice_name} - ID {deployment?.notice_id}
        </span>
      </div>

      <div className={tx('leading-body-big')} data-cy="disclaimer-info-row" data-skeleton>
        <span className={tx('font-semibold')}>Notice Version:</span> <span>{deployment?.id}</span>
      </div>

      <div className={tx('leading-body-big')} data-cy="disclaimer-info-row" data-skeleton>
        <span className={tx('font-semibold')}>Notice Type:</span>{' '}
        <span>{TEXT_PER_PLATFORM[deployment?.platform]}</span>
      </div>
    </aside>
  );
};
