import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import React from 'react';
import { useActiveOrganization } from '@didomi/utility-react';
import { ErrorState, ProtectedRoute } from '@components';
import { ACCESS_POLICIES_CONFIG, ROUTES } from '@constants';
import { HomeLayout } from '@layouts';
import { NoticeVersions, ConsentProofs } from '@pages';

const getRouter = (basename: string) =>
  createBrowserRouter(
    [
      {
        path: '/',
        errorElement: <ErrorState />,
        children: [
          {
            path: '',
            element: <HomeLayout />,
            children: [
              {
                index: true,
                element: <Navigate to={ROUTES.VERSIONS} />,
              },
              {
                path: ROUTES.VERSIONS,
                element: (
                  // React router renders ProtectedRoute as a singleton(same instance for all routes) - 'key' attribute is used to prevent this to happen
                  <ProtectedRoute
                    key="versions"
                    redirectPath={ROUTES.PROOFS}
                    requiredAccessPolicies={ACCESS_POLICIES_CONFIG.VERSIONS_VIEWER}
                  >
                    <NoticeVersions />
                  </ProtectedRoute>
                ),
              },
              {
                path: ROUTES.PROOFS,
                element: (
                  // React router renders ProtectedRoute as a singleton(same instance for all routes) - 'key' attribute is used to prevent this to happen
                  <ProtectedRoute
                    key="proofs"
                    redirectPath={ROUTES.VERSIONS}
                    requiredAccessPolicies={ACCESS_POLICIES_CONFIG.PROOFS_VIEWER}
                  >
                    <ConsentProofs />
                  </ProtectedRoute>
                ),
              },
            ],
          },
        ],
      },
    ],
    { basename },
  );

export const OrganizationRouterProvider = () => {
  const { organizationId } = useActiveOrganization();
  const basename = `/${organizationId}/versions-and-proofs`;

  return <RouterProvider router={getRouter(basename)} />;
};
