import { AccessTypes, AvailableModules, modules } from '@didomi/authorization-metadata';

const VERSIONS_POLICIES_METADATA = modules[AvailableModules.NoticeVersions];
const PROOFS_POLICIES_METADATA = modules[AvailableModules.ConsentProofReports];

// Versions And Proofs access policies config
export const ACCESS_POLICIES_CONFIG = {
  VERSIONS_VIEWER: VERSIONS_POLICIES_METADATA.accessTypeAllowedPolicies[AccessTypes.Viewer],
  PROOFS_VIEWER: PROOFS_POLICIES_METADATA.accessTypeAllowedPolicies[AccessTypes.Viewer],
  PROOFS_EDITOR: PROOFS_POLICIES_METADATA.accessTypeAllowedPolicies[AccessTypes.Editor],
};
