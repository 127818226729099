import { ExtendedNoticeDeployment, NoticeDeploymentsFilters } from '@types';

const matchNotices = (notices: string[], deployment: ExtendedNoticeDeployment) => {
  return notices.length === 0 || notices.includes(deployment.notice_id);
};

const matchPlatforms = (platforms: string[], deployment: ExtendedNoticeDeployment) => {
  return platforms.length === 0 || platforms.includes(deployment.platform);
};

const matchUsers = (users: string[], deployment: ExtendedNoticeDeployment) => {
  return users.length === 0 || users.includes(deployment.deployed_by);
};

const matchPublishDate = (datesRange: [number, number], deployment: ExtendedNoticeDeployment) => {
  if (!datesRange) return true;

  const [from, to] = datesRange;
  const deploymentDate = new Date(deployment.deployed_at).getTime();
  return deploymentDate >= from && deploymentDate <= to;
};

const matchVersionId = (versionId: string, deployment: ExtendedNoticeDeployment) => {
  return (
    !versionId || deployment.notice_deployment.id.toLowerCase().includes(versionId.toLowerCase())
  );
};

const matchTargets = (targets: string[], deployment: ExtendedNoticeDeployment) => {
  return targets.length === 0 || targets.some(t => deployment.targets.includes(t));
};

export const matchNoticeDeploymentsFilters =
  (filters: NoticeDeploymentsFilters) => (noticeDeployment: ExtendedNoticeDeployment) => {
    if (!filters) return true;

    const { notices, platforms, users, publishDate, versionId, targets } = filters;

    return (
      matchNotices(notices, noticeDeployment) &&
      matchPlatforms(platforms, noticeDeployment) &&
      matchUsers(users, noticeDeployment) &&
      matchPublishDate(publishDate, noticeDeployment) &&
      matchVersionId(versionId, noticeDeployment) &&
      matchTargets(targets, noticeDeployment)
    );
  };
