import { css } from '@twind/core';

const squaredBackground = () => css`
  background-color: #f9fafa;
  background-image: repeating-conic-gradient(#f9fafa 0deg 25%, white 0deg 50%);
  background-position: 12px 0, 12px 0, 0 0, 0 0;
  background-size: 12px 12px;
  background-repeat: repeat;
`;

const rules = [['bg-squared', squaredBackground]];

export const themePreset = {
  rules,
  theme: {
    gridTemplateColumns: {
      'fill-300': 'repeat(auto-fill, minmax(300px, 1fr))',
    },
  },
};
