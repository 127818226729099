import { ConsentProofReport, ExtendedConsentProofReport, UserIdType } from '@types';

export const extendAllReports = (reports: ConsentProofReport[]): ExtendedConsentProofReport[] => {
  return reports.map(extendProofReport);
};

export const extendProofReport = (report: ConsentProofReport): ExtendedConsentProofReport => {
  const isOrgUserId = report.search_parameters.user_id_type === UserIdType.ORG_USER_ID;

  return {
    ...report,
    // We need to split the user_id into user_id and org_user_id - so we can sort them properly in the table
    user_id: isOrgUserId ? '' : report.search_parameters.user_id,
    org_user_id: isOrgUserId ? report.search_parameters.user_id : '',
  };
};
