import { NavLink, Outlet } from 'react-router-dom';
import React from 'react';
import {
  DidomiFeatureHeader,
  DidomiNavTabHeaders,
  DidomiNavTabs,
  DidomiSmallPush,
  DidomiNavTabHeading,
  DidomiNavTabHeader,
  DidomiNavTabContent,
  DidomiNavTabContents,
} from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { TRACKING_SELECTORS, ACCESS_POLICIES_CONFIG, ROUTES } from '@constants';

const NO_ACCESS_TAB_TOOLTIP =
  'You do not have permission to view this tab. Please reach out to your administrator.';

export const HomeLayout = () => {
  const { hasAccess: hasAccessToVersions } = useHasAccessPolicies(
    ACCESS_POLICIES_CONFIG.VERSIONS_VIEWER,
  );
  const { hasAccess: hasAccessToReports } = useHasAccessPolicies(
    ACCESS_POLICIES_CONFIG.PROOFS_VIEWER,
  );

  return (
    <section className="p-l pb-unsafe-bottom-space text-primary-blue-6">
      <DidomiFeatureHeader titleText="Versions & Proofs" className="w-full block mb-s">
        <div slot="description">
          Autonomously consult historical data of published notices and user consents of any given
          point in time
        </div>

        <DidomiSmallPush
          href="https://support.didomi.io/versions-proofs"
          target="_blank"
          slot="actions"
          text="Help Center"
          highlighted-text="Versions & Proofs"
          smallIcon="right-on"
          data-tracking={TRACKING_SELECTORS.common.helpCenterLink}
        ></DidomiSmallPush>
      </DidomiFeatureHeader>

      <DidomiNavTabs>
        <DidomiNavTabHeading>
          <DidomiNavTabHeaders>
            <DidomiNavTabHeader
              disabledTooltip={NO_ACCESS_TAB_TOOLTIP}
              disabled={!hasAccessToVersions}
              iconRight={hasAccessToVersions ? '' : 'lock'}
            >
              <NavLink
                className={navData => (navData.isActive ? 'didomi-active-tab' : '')}
                to={ROUTES.VERSIONS}
              >
                Notice Versions
              </NavLink>
            </DidomiNavTabHeader>
            <DidomiNavTabHeader
              disabledTooltip={NO_ACCESS_TAB_TOOLTIP}
              disabled={!hasAccessToReports}
              iconRight={hasAccessToReports ? '' : 'lock'}
            >
              <NavLink
                className={navData => (navData.isActive ? 'didomi-active-tab' : '')}
                to={ROUTES.PROOFS}
              >
                Consent Proofs Reports
              </NavLink>
            </DidomiNavTabHeader>
          </DidomiNavTabHeaders>
        </DidomiNavTabHeading>

        <DidomiNavTabContents>
          <DidomiNavTabContent className="pt-m">
            <Outlet />
          </DidomiNavTabContent>
        </DidomiNavTabContents>
      </DidomiNavTabs>
    </section>
  );
};
