import React from 'react';
import { DidomiButton, DidomiIconButton, DidomiTooltip } from '@didomi/ui-atoms-react';
import { TRACKING_SELECTORS } from '@constants';
import { ReportStatus } from '@types';

interface ReportsTableActionsProps {
  status: ReportStatus;
  isCancelAllowed: boolean;
  onDownloadCsvClicked: () => void;
  onCancelClicked: () => void;
}

export const ReportsTableActions = ({
  status,
  isCancelAllowed,
  onDownloadCsvClicked,
  onCancelClicked,
}: ReportsTableActionsProps) => {
  return (
    <>
      {isCancelAllowed && status === ReportStatus.GENERATING && (
        <DidomiButton
          size="small"
          data-cy="stop-report"
          data-tracking={TRACKING_SELECTORS.proofs.stopReportBtn}
          onClick={onCancelClicked}
        >
          Stop
        </DidomiButton>
      )}

      {status === ReportStatus.COMPLETED && (
        <DidomiTooltip content="Download CSV" placement="top">
          <DidomiIconButton
            icon="download"
            variant="rounded"
            data-cy="download-csv"
            data-tracking={TRACKING_SELECTORS.proofs.extractCsvBtn}
            onClick={onDownloadCsvClicked}
          />
        </DidomiTooltip>
      )}
    </>
  );
};
