import React from 'react';
import { Icons } from '@didomi/ui-atoms';
import { DidomiIcon } from '@didomi/ui-atoms-react';
import { TEXT_PER_PLATFORM } from '@constants';
import { Platform } from '@types';

const ICON_PER_PLATFORM: Record<Platform, Icons> = {
  amp: 'amp',
  web: 'website',
  app: 'mobile',
  ctv: 'ctv',
};

interface PlatformTableCellProps {
  platform: Platform;
}

export const PlatformTableCell = ({ platform }: PlatformTableCellProps) => {
  return (
    <div className="flex gap-1 items-center">
      <DidomiIcon name={ICON_PER_PLATFORM[platform]} className="text-primary-blue-5" />{' '}
      {TEXT_PER_PLATFORM[platform]}
    </div>
  );
};
