import React, { useMemo, useState } from 'react';
import { filterSortPaginate } from '@didomi/helpers';
import { useTableState } from '@didomi/helpers-react';
import { DidomiEmptyState, DidomiPaginator, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import {
  ConsentProofsReportsTable,
  Loader,
  QuotaCounter,
  RequestReportForm,
  ResultsText,
} from '@components';
import { ACCESS_POLICIES_CONFIG } from '@constants';
import { useConsentProofsApi } from '@hooks';
import { ConfirmStopReportModal, LoadingModal } from '@modals';
import { DIALOG_CLOSED_STATE, DialogState, INITIAL_TABLE_STATE } from './ConsentProofsHelpers';

export const ConsentProofs = () => {
  const [dialogState, setDialogState] = useState<DialogState>(DIALOG_CLOSED_STATE);
  const { hasAccess: hasEditAccess } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.PROOFS_EDITOR);
  const {
    reports,
    reportsQuota,
    deleteReport,
    requestReport,
    downloadProofReportCsv,
    isLoading,
    isSaving,
  } = useConsentProofsApi();

  const tableState = useTableState(INITIAL_TABLE_STATE);

  const { data: reportsToDisplay, total: paginationTotal } = useMemo(
    () =>
      filterSortPaginate(reports, {
        search: tableState.search,
        sortConfig: tableState.sortConfig,
        limit: tableState.limit,
        page: tableState.page,
        searchFields: [],
      }),
    [tableState, reports],
  );

  const isEmpty = reportsToDisplay?.length === 0;
  const reachedMaxReportsQuota =
    reportsQuota && reportsQuota.used_reports >= reportsQuota.max_reports_per_period;

  return (
    <section className="w-full">
      <div className="flex gap-xs justify-end mb-xxs">
        <DidomiSkeleton className="self-start" isLoading={isLoading}>
          <QuotaCounter
            currentValue={reportsQuota?.used_reports}
            maxValue={reportsQuota?.max_reports_per_period}
            quotaLabel="Reports generated"
            quotaReachedLabel="Monthly limit reached"
          />
        </DidomiSkeleton>
        {hasEditAccess && (
          <RequestReportForm disabled={reachedMaxReportsQuota} onSubmit={requestReport} />
        )}
      </div>

      {!isEmpty && (
        <>
          <ResultsText count={paginationTotal} />

          <ConsentProofsReportsTable
            className="mb-xs"
            hasEditAccess={hasEditAccess}
            reports={reportsToDisplay}
            sortConfig={tableState.sortConfig}
            onSortChange={tableState.changeSorting}
            onCancelReportClicked={report => setDialogState({ type: 'delete', context: report })}
            onDownloadCsvClicked={report => downloadProofReportCsv(report.id)}
          />

          <div className="flex justify-end">
            <DidomiPaginator
              data-testid="reports-paginator"
              className="self-end"
              page={tableState.page}
              itemCount={paginationTotal}
              size={tableState.limit as any}
              onPageSizeChange={tableState.changeLimit}
              onPageChange={tableState.changePage}
            />
          </div>
        </>
      )}

      {/* Empty State */}
      {isEmpty && !isLoading && (
        <DidomiEmptyState illustration-name="traces-no-result-found">
          <div slot="title">It&apos;s empty here!</div>
          No results
        </DidomiEmptyState>
      )}

      {/* Loading State */}
      {isLoading && <Loader>Loading reports</Loader>}

      {/* Modals */}
      <LoadingModal isOpen={isSaving} title="Saving changes..." />

      <ConfirmStopReportModal
        isOpen={dialogState.type === 'delete'}
        report={dialogState.context}
        onCancel={() => setDialogState(DIALOG_CLOSED_STATE)}
        onConfirm={() => {
          deleteReport(dialogState.context.id);
          setDialogState(DIALOG_CLOSED_STATE);
        }}
      />
    </section>
  );
};
