import { PaginatedResponse } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { NoticeDeployment } from '@types';
import { buildUrl, isValidDeployment } from '@utils';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

/**
 * Fetch notice deployments
 * */
export const useNoticeDeployments = (options?: UseQueryOptions<NoticeDeployment[]>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchDeployments = async (): Promise<NoticeDeployment[]> => {
    const params = {
      $select: [
        'id',
        'platform',
        'targets',
        'notice_id',
        'deployed_at',
        'deployed_by',
        'deployed_by_name',
        'deployed_metadata',
      ],
      organization_id: organizationId,
      $limit: 5000,
      $with: ['notice_deployment'],
    };
    const url = buildUrl(`${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/configs`, params);
    const response = await axiosWithInterceptors.get<PaginatedResponse<NoticeDeployment>>(url);

    return response.data.data.filter(isValidDeployment);
  };

  return useQuery<NoticeDeployment[], AxiosError>(
    QUERY_KEYS.DEPLOYMENTS(organizationId),
    fetchDeployments,
    options,
  );
};
