import React from 'react';
import { DidomiLoader, DidomiModal, DidomiModalHeader } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type LoadingModalProps = {
  isOpen: boolean;
  title: string;
  subTitle?: string;
};

export const LoadingModal = ({ isOpen, title, subTitle = 'Please wait' }: LoadingModalProps) => (
  <DidomiModal isOpen={isOpen} permanent="true" variant="slim">
    <div className={tx('mb-s flex justify-center items-center')}>
      <DidomiLoader />
    </div>
    <DidomiModalHeader modalTitle={title} modalSubTitle={subTitle} />
  </DidomiModal>
);
