const FILE_NAME_REGEX = /filename="([^"]*)"/;

/**
 * Extracts file name from content disposition header
 * @example "attachment; filename="notice.json"" => "notice.json"
 */
export const extractFileNameFromContentDisposition = (contentDisposition: string): string => {
  const match = contentDisposition.match(FILE_NAME_REGEX);
  return match ? match[1] : 'export.txt';
};
